import SectionLargeSlider from 'app/(home)/SectionLargeSlider';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import SectionBecomeAnAuthor from 'components/SectionBecomeAnAuthor/SectionBecomeAnAuthor';
import SectionSliderNewCategories2 from 'components/SectionSliderNewCategories2/SectionSliderNewCategories2';
import SectionSliderNewCategories3 from 'components/SectionSliderNewCategories3/SectionSliderNewCategories3';
import SectionGridPosts from 'components/Sections/SectionGridPosts';
import SectionLatestPosts from 'components/Sections/SectionLatestPosts';
import { DEMO_POSTS_VIDEO } from 'data/posts';
import { useCategoriesQuery } from 'framework/rest/category/categories.query';
import { usePostsQuery } from 'framework/rest/post/posts.query';
import { Category } from 'framework/rest/types';
import { useAtom } from 'jotai';
import { generalSettingsAtom } from 'store/atom/general-settings';
import img1 from '../../images/c1.png';
import img2 from '../../images/c2.png';
import img3 from '../../images/c3.png';
import img4 from '../../images/c4.png';
import img5 from '../../images/c5.png';

const CategoryData: Category[] = [
    {
        id: 1,
        name: '7/24 Destek Hattı',
        image: [
            {
                id: 1,
                original: img1,
                thumbnail: img1,
            },
        ],
        banner_image: {
            id: 1,
            original: img1,
            thumbnail: img1,
        },
    },
    {
        id: 2,
        name: 'Reklam Yönetimi',
        image: [
            {
                id: 2,
                original: img2,
                thumbnail: img2,
            },
        ],
        banner_image: {
            id: 2,
            original: img2,
            thumbnail: img2,
        },
    },
    {
        id: 3,
        name: 'Kargo Anlaşmaları',
        image: [
            {
                id: 3,
                original: img3,
                thumbnail: img3,
            },
        ],
        banner_image: {
            id: 3,
            original: img3,
            thumbnail: img3,
        },
    },
    {
        id: 4,
        name: 'Ek Masraflar Yok',
        image: [
            {
                id: 4,
                original: img4,
                thumbnail: img4,
            },
        ],
        banner_image: {
            id: 4,
            original: img4,
            thumbnail: img4,
        },
    },
    {
        id: 5,
        name: 'Geçiş Kolaylığı',
        image: [
            {
                id: 5,
                original: img5,
                thumbnail: img5,
            },
        ],
        banner_image: {
            id: 5,
            original: img5,
            thumbnail: img5,
        },
    },
];

const PageHome = () => {
    const [generalSettings] = useAtom(generalSettingsAtom);
    const { data, isLoading } = useCategoriesQuery({
        limit: 10,
        sortedBy: 'asc',
        orderBy: 'sort',
        featured: 1,
    });
    const { data: posts } = usePostsQuery({
        limit: 10,
        featured: 1,
    });

    const featuredCategory = data?.categories.data;

    const subTitleHeader = () => (
        <div>
            <div>Başarılı bir e-ticaret için ihtiyacınız olan her şey</div>
        </div>
    );

    const subTitleBanner = () => (
        <div>
            <div>Sektörünüze özel kapsamlı çözümlerimiz ve özel anlaşmalarımızla</div>
            <div>İşinizi çok daha kolaylaştırıyoruz</div>
        </div>
    );

    return (
        <div className="nc-PageHome relative">
            <div className="container relative">
                <SectionLargeSlider
                    heading={generalSettings?.siteTitleHome || "Türkiye'nin E-Ticaret Altyapısı"}
                    subTitle={generalSettings?.siteSubtitleHome || subTitleHeader()}
                    className="pt-10 pb-16 md:py-16 lg:pb-28 lg:pt-20"
                    posts={posts?.posts.data!}
                />

                {featuredCategory && (
                    <div className="relative pt-16">
                        <BackgroundSection />
                        <SectionSliderNewCategories2
                            heading="Uzmanlardan birebir destek!"
                            subHeading="Websitenizi oluştururken sektöre hakim uzmanlar, her adımda yanınızda!"
                            categories={featuredCategory!}
                        />
                    </div>
                )}

                <SectionSliderNewCategories3
                    className="py-16 lg:py-28"
                    heading="Profesyonel özellikler"
                    subHeading={subTitleBanner()}
                    categories={CategoryData}
                    categoryCardType="card4"
                />

                {/* <div className="relative py-16">
                <BackgroundSection />
                <SectionSliderPosts
                    postCardName="card9"
                    heading="Explore latest audio articles"
                    subHeading="Click on the icon to enjoy the music or podcast 🎧"
                    posts={DEMO_POSTS_AUDIO.filter((_, i) => i > 3 && i < 10)}
                />
                </div> */}

                {/* <SectionMagazine1 className="py-16 lg:py-28" posts={MAGAZINE1_POSTS} /> */}

                {/* <SectionAds /> */}

                {/* <SectionMagazine7
                className="py-16 lg:py-28"
                posts={DEMO_POSTS_GALLERY.filter((_, i) => i < 6)}
                /> */}
            </div>

            <div className="dark bg-neutral-900 dark:bg-black dark:bg-opacity-20 text-neutral-100">
                <div className="relative container">
                    <SectionGridPosts
                        className="py-16 lg:py-28"
                        headingIsCenter
                        postCardName="card10V2"
                        heading="Başarı Hikayeleri"
                        subHeading="KIPS deneyimini kullanıcılardan dinleyin"
                        posts={DEMO_POSTS_VIDEO.filter((_, i) => i > 5 && i < 12)}
                        gridClass="md:grid-cols-2 lg:grid-cols-3"
                    />
                </div>
            </div>

            <div className="container ">
                {/* <SectionMagazine8
                className="py-16 lg:py-28"
                posts={DEMO_POSTS_AUDIO.filter((_, i) => i < 6)}
                /> */}

                {/* <div className="relative py-16">
                <BackgroundSection />
                <SectionMagazine9
                    posts={DEMO_POSTS_AUDIO.filter((_, i) => i >= 6 && i < 15)}
                />
                </div> */}

                {/* <SectionGridAuthorBox
                    className="py-16 lg:py-28 lg:pb-8"
                    authors={DEMO_AUTHORS.filter((_, i) => i < 10)}
                /> */}

                <div className="relative py-16 mt-16">
                    <BackgroundSection />
                    <SectionBecomeAnAuthor />
                </div>

                <SectionLatestPosts className="pb-16 pt-16 lg:pb-28" />
            </div>
        </div>
    );
};

export default PageHome;
