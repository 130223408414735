import Logo from 'components/Logo/Logo';
import SocialsList1 from 'components/SocialsList1/SocialsList1';
import { CustomLink } from 'data/types';
import { useAtom } from 'jotai';
import React from 'react';
import { settingsAtom } from 'store/atom/settings.atom';

export interface WidgetFooterMenu {
    id: string;
    title: string;
    menus: CustomLink[];
}

const Footer: React.FC = () => {
    const [settings] = useAtom(settingsAtom);

    const renderWidgetMenuItem = (menu: any, index: number) => {
        return (
            menu?.status === true && (
                <div
                    key={index}
                    className="text-sm"
                >
                    <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">{menu.widgetTitle}</h2>
                    <ul className="mt-5 space-y-4">
                        {menu.lists.map((item: any, index: number) => (
                            <li key={index}>
                                <a
                                    key={index}
                                    className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                                    href={item.path}
                                >
                                    {item.title}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            )
        );
    };

    return (
        <div className="nc-Footer relative py-16 lg:py-28 border-t border-neutral-200 dark:border-neutral-700">
            <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
                <div className="grid grid-cols-4 grid-rows-2 gap-1 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col lg:gap-5">
                    <div className="col-span-2 col-start-1 row-start-1 row-span-1 md:col-span-1">
                        <Logo />
                    </div>
                    <div className="col-span-4 row-span-1 col-start-1 row-start-2 flex items-center md:col-span-3">
                        <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
                    </div>
                </div>
                {settings?.footer?.widgets?.length > 0 && settings?.footer?.widgets.map(renderWidgetMenuItem)}
            </div>
        </div>
    );
};

export default Footer;
